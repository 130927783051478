.text-h1 {
    text-align: center;
    font-size: 40px !important;
    font-weight: 700 !important;
    color: rgb(97, 97, 97);
}

.no-data {
    text-align: center !important;
    font-size: 15px !important;
    font-weight: bold !important;
    color: rgb(138, 138, 138) !important;
}

.cancel-button {
    max-height: 45px !important;
    margin-right: 10px !important;
    margin-bottom: 15px !important;
    color: rgb(255, 255, 255) !important;
    background-color: #929292 !important;
    border-radius: 10px !important;
}

.save-button {
    max-height: 45px !important;
    margin-bottom: 15px !important;
    color: rgb(255, 255, 255) !important;
    background-color: #4775d8 !important;
    border-radius: 10px !important;
}

.add-button {
    max-height: 45px !important;
    margin-top: 20px !important;
    color: rgb(36, 114, 59) !important;
    background-color: #69c571 !important;
    border-radius: 10px !important;
}

.edit-button {
    color: #ffffff !important;
    min-width: 24px !important;
    padding: 5px !important;
    margin-right: 5px !important;
    background-color: #3d7bd6 !important;
}

.delete-button {
    color: #ffffff !important;
    min-width: 24px !important;
    padding: 5px !important;
    background-color: #e06e6e !important;
}

.label {
    font-size: 15px !important;
    font-weight: bold !important;
    text-align: center !important;
    margin: 0 0 0.5rem 0 !important;
}

.field {
    text-align: center !important;
}

.groups-area {
    background-color: burlywood !important;
    border-radius: 5px !important;
    padding: 1px 2.5px !important;
    margin: 0 1.5px !important;
    display: inline-block !important;
}

.dialog-title {
    font-size: 25px !important;
    font-weight: bold !important;
    padding-bottom: 5px !important;
    color: rgb(255, 91, 91) !important;
}