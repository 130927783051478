.essential-text {
  margin-top: -50px !important;
  text-align: center;
  font-size: 16px;
  font-weight: bold !important;
  color: #e05858;
}

.text-h1 {
  text-align: center;
  font-size: 40px !important;
  font-weight: 700 !important;
  color: rgb(97, 97, 97);
}

.tab {
  font-weight: bold !important;
  border-radius: 20px 20px 0 0 !important;
  transition: background-color 0.5s cubic-bezier(.4,0,.2,1), color 0.5s cubic-bezier(.4,0,.2,1), font-size 0.5s, box-shadow 0.5s !important;
  transition-delay: 0.2s !important;
}

.active-tab {
  border-radius: 20px 20px 0 0 !important;
  background-color: rgb(172, 172, 201) !important;
  color: white !important;
  animation: fadeIn 0.5s !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.griditem {
    margin-top: 5px !important;
}

.delete-button {
    color: #ffffff !important;
    background-color: #e05858 !important;
}

.add-button {
    color: rgb(36, 114, 59) !important;
    background-color: #69c571 !important;
    border-radius: 15px !important;
}

.success-text {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #28a533;
}

.error-text {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}