.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    min-width: 1280px;
}

.left-card {
    border: 1px solid #E5E5E5;
    border-radius: 15px !important;
    height: calc(100vh - 50px);
    max-height: calc(100vh - 50px - 40px - 2px);
    min-height: 500px;
    margin: 20px;
    position: relative;
}

.options-icon {
    font-size: 28px;
    color: #9383ec;
    position: absolute;
    top: 10px;
    left: 10px;
    transition: transform 0.5s;
    &:hover {
        cursor:pointer;
        transform: scale(1.4);
    }
}

.avatar-card {
    text-align: center;
    border-radius: 15px !important;
    top: 50px;
    margin: 20px;
    padding: 15px 15px 0 15px;
    background-color: #F1F4FB !important;
}

.avatar {
    width: 100px !important;
    height: 100px !important;
    position: absolute !important;
    top: -55px;
    left: calc(50% - 55px);
    border: 5px solid #FFF;
    &:hover {
        animation: shake 1s;
    }
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    12.5% {
        transform: translateX(-10px);
    }
    25% {
        transform: translateX(10px);
    }
    37.5% {
        transform: translateX(-8px);
    }
    50% {
        transform: translateX(8px);
    }
    62.5% {
        transform: translateX(-6px);
    }
    75% {
        transform: translateX(6px);
    }
    87.5% {
        transform: translateX(-4px);
    }
}

.account-linked {
    width: 30px !important;
    height: 30px !important;
    color: #345eb9;
    position: absolute !important;
    top: -15px;
    right: -10px;
    transform: rotate(-45deg);
    transition: transform 0.3s;
    &:hover {
        cursor:pointer;
        transform: rotate(-45deg) scale(1.2);
    }
}

.account-unlinked {
    width: 30px !important;
    height: 30px !important;
    color: #ff8888;
    position: absolute !important;
    top: -15px;
    right: -12px;
    transform: rotate(-55deg);
    transition: transform 0.3s;
    &:hover {
        cursor:pointer;
        transform: rotate(-55deg) scale(1.2);
    }
}

.avatar-card-edit {
    width: 20px;
    height: 20px;
    color: #c7c7c7;
    background-color: #fff;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 1px -1px #dae4fb,0px 1px 1px 0px #dae4fb,0px 1px 3px 0px #dae4fb;
    position: absolute;
    bottom: 7px;
    right: 7px;
    transition: transform 0.3s;
    &:hover {
        cursor:pointer;
        transform: scale(1.2);
    }
}

.gender-male {
    width: 0.8em !important;
    height: 0.8em !important;
    color: #3f51b5;
    margin-left: 5px;
}

.gender-female {
    width: 0.8em !important;
    height: 0.8em !important;
    color: #f50057;
    margin-left: 5px;
}

.status-card {
    border: 1px solid #E5E5E5;
    border-radius: 10px !important;
    box-shadow: 0px 2px 1px -1px #dae4fb,0px 1px 1px 0px #dae4fb,0px 1px 3px 0px #dae4fb !important;
}

.status-cardcontent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 20px !important;
}

.status-indicator-online {
    width: 10px;
    height: 10px;
    background-color: #01ae5d;
    margin-right: 10px;
    border-radius: 50%;
    position: relative; /* 新增這行確保效果相對於圓點 */
}

.status-indicator-online::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 174, 93, 0.5); /* 設定初始顏色和透明度 */
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: spread 2s infinite;
}

@keyframes spread {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(2.5);
        opacity: 0;
    }
}

.status-text-online {
    font-size: 15px !important;
    font-weight: 600;
    color: #01ae5d;
    animation: breathe 3s infinite;
}

@keyframes breathe {
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0.9);
        opacity: 0.8;
    }
}

.status-indicator-offline {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #f06868;
}

.status-text-offline {
    font-size: 15px !important;
    font-weight: 600;
    color: #f06868;
}

.status-indicator-none {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #cccccc;
}

.status-text-none {
    font-size: 15px !important;
    font-weight: 600;
    color: #afafaf;
}

.account-card {
    position: relative;
    text-align: center;
    height: calc(100% - 50px - 212px - 30px);
    top: 50px;
    margin: 20px;
    border: 1px solid #E5E5E5;
    border-radius: 15px !important;
    overflow: hidden;
}

.account-photo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 10px;
}

.account-info-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 25px;
    flex-wrap: wrap;
}

.account-active-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 25px 0 25px;
}

.account-active-button {
    width: 3em !important;
    height: 3em !important;
    color: #01ae5d;
    font-weight: 600;
    padding-top: 3px;
    &:hover {
        cursor:pointer;
    }
}

.account-active-text {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    right: 49%;
}

.account-active-text::before {
    content: '啟用';
}

.account-inactive-button {
    width: 3em !important;
    height: 3em !important;
    color: #f06868;
    font-weight: 600;
    padding-top: 3px;
    &:hover {
        cursor:pointer;
    }
}

.account-inactive-text {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    left: 49%;
}

.account-inactive-text::before {
    content: '停用';
}

.info-box {
    display: flex;
}

.info-card {
    min-width: 280px;
    border: 1px solid #E5E5E5;
    border-radius: 15px !important;
    margin: 20px 20px 20px 0;
    transform: translateY(100%);
    opacity: 0;
    animation: slideInUp 0.5s forwards;
}

@keyframes slideInUp {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.info-cardcontent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px !important;
}

.icon-box-1 {
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #FFF;
    border-radius: 15px !important;
    background-color: #eaf0ff;
}

.icon-box-2 {
    padding: 15px;
    margin-right: 20px;
    border: 1px solid #FFF;
    border-radius: 15px !important;
    background-color: #c1e7c3 ;
}

.icon-box-3 {
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #FFF;
    border-radius: 15px !important;
    background-color: #fff3d4;
}

.icon-box-4 {
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #FFF;
    border-radius: 15px !important;
    background-color: #f0f0f0;
}

.info-description {
    /* 內容不換行 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.details-card {
    height: calc(100vh - 252px);
    max-height: calc(100vh - 252px);
    min-height: 340px;
    border: 1px solid #E5E5E5;
    border-radius: 15px !important;
    margin: 0 20px 20px 0;
}

.details-cardcontent {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.details-title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E5E5E5;
}

.details-content-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: calc(100% - 40px - 8px);
    overflow: auto;
    position: relative;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    min-width: 300px;
}

.employee-option-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 10px !important;
    transition: background-color 0.1s;
    &:hover {
        cursor:pointer;
        background-color: #668be2;
    }
}

.current-employee-icon {
    width: 18px !important;
    height: 18px !important;
    font-weight: bold;
    color: #fff;
    background-color: #668be2;
    border-radius: 50%;
    .employee-option-box:hover & {
        color: #668be2;
        background-color: #fff;
        transition: color 0.1s, background-color 0.1s;
    }
}

.add-employeeinfo-card {
    font-size: 14px !important;
    font-weight: bold !important;
    margin: 20px 0 10px 0 !important;
    &:hover {
        color: #668be2;
        border-bottom: 1px solid #668be2;
        margin-bottom: 9px !important;
    }
}

.photo-upload-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 0 20px 0;
    min-width: 300px;
}