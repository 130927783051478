.main-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.category-box {
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 4px;
  margin-top: 5px;
}

.category-button {
  height: 25px;
  color: #525252 !important;
  border-radius: 10px !important;
  padding: 2px 4px !important;
  transition: transform 0.3s !important;

  &:hover {
    background-color: #a1a1aa !important;
    color: #fff !important;
    transform: scale(1.1);
  }
}

.category-button-selected {
  height: 25px;
  background-color: #3f3f46 !important;
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  padding: 2px 4px !important;
  transform: scale(1.1);
}

.content-box {
  height: calc(100vh - 104px - 48px - 37.5px - 47.5px - 38px - 2px);
  min-height: 300px;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  overflow-y: hidden;
}

.tab-main-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.chip-text {
  height: 25px !important;
  font-weight: bold;
  line-height: 15px;
  color: #323b76 !important;
  background-color: #a7bee9 !important;
}

.delete-icon {
  font-size: 23px !important;
  color: #a1a1aa !important;
  position: absolute;
  top: -7px;
  right: -7px;
  transform: rotate(35deg);
  transition:
    top 0.3s ease-in-out,
    right 0.3s ease-in-out,
    transform 0.3s ease-in-out,
    font-size 0.3s ease-in-out
  !important;

  &:hover {
    font-size: 30px !important;
    color: #f87171 !important;
    cursor: pointer;
    top: -10px;
    right: -10px;
    transform: rotate(0deg);
  }
}

.sub-box {
  background-color: #cbd5e1;
  border-radius: 10px;
  padding: 8px 16px;
  margin: 0 16px;
  position: relative;
}

.question-user {
  line-height: 24px !important;
  font-weight: bold;
}

.question-time {
  font-size: 13px !important;
  color: rgb(136, 136, 136) !important;
  padding: 5px 55px 5px 55px !important;
}

.question {
  font-size: 16px !important;
  font-weight: bold !important;
  color: rgb(230, 77, 77) !important;
  padding-left: 35px;
  padding-right: 25%;
}

.edit-question {
  text-align: center;
  font-size: 15px !important;
  color: rgb(49, 93, 160) !important;
  padding: 10px 55px 5px 55px;
}

.question-target {
  display: flex;
  justify-content: right;
  padding-right: 35px;
  margin-top: 3px;
}

.reply-user {
  line-height: 24px;
  font-weight: bold;
}

.reply-time {
  font-size: 13px !important;
  color: rgb(136, 136, 136) !important;
  padding: 5px 55px 5px 55px !important;
}

.reply {
  display: flex;
  justify-content: right;
  font-size: 16px !important;
  font-weight: bold !important;
  color: rgb(116, 153, 221) !important;
  padding-left: 25%;
  padding-right: 35px;
}

.reply-empty {
  text-align: center;
  font-size: 15px !important;
  color: rgb(224, 133, 126) !important;
  padding: 10px 55px 5px 55px;
}

.no-reply {
  text-align: center;
  font-size: 15px !important;
  color: rgb(126, 165, 224) !important;
  padding: 10px 55px 5px 55px;
}

.edit-reply {
  text-align: center;
  font-size: 15px !important;
  color: rgb(49, 93, 160) !important;
  padding: 10px 55px 5px 55px;
  margin-top: 10px !important;
}

.snackbar {
  transform: translateY(100%);
  opacity: 0;
  animation: AlertslideInUp 1.5s forwards, AlertfadeOut 1.5s forwards 5s;
}

@keyframes AlertslideInUp {
  from {
      transform: translateY(100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes AlertfadeOut {
  from {
      opacity: 1;
      transform: translateY(0);
  }
  to {
      opacity: 0;
      transform: translateY(-100%); /* 讓元素滑出並消失 */
  }
}

.action-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.edit-button {
  height: 25px;
  color: #525252 !important;
  border: 1px solid #979797 !important;
  border-radius: 10px !important;
  padding: 8px 6px !important;

  &:hover {
    background-color: #d1d1d1 !important;
    font-weight: bold !important;
  }
}

.cancel-button {
  height: 25px;
  color: #878ca7 !important;
  border: 1px solid #979797 !important;
  border-radius: 10px !important;
  padding: 8px 6px !important;

  &:hover {
    background-color: #dde1e8 !important;
    font-weight: bold !important;
  }
}

.save-button {
  height: 25px;
  color: #4476ff !important;
  border: 1px solid #979797 !important;
  border-radius: 10px !important;
  padding: 8px 6px !important;

  &:hover {
    background-color: #bcd3ff !important;
    font-weight: bold !important;
  }
}

.add-item-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  position: sticky;
  bottom: 0;
}

.add-item-button {
  height: 30px;
  color: #fff !important;
  background-color: #22c55e !important;
  border-radius: 15px !important;
  padding: 8px 10px !important;

  &:hover {
    background-color: #16a34a !important;
    font-weight: bold !important;
  }
}

.delete-cancel-button {
  color: #ffffff !important;
  background-color: #acacac !important;
  line-height: 20px !important;   
  border-radius: 20px !important;
  padding: 4px 12px 4px 8px !important;

  &:hover {
    background-color: #8c8c8c !important;
  }
}

.delete-confirm-button {
  color: #ffffff !important;
  background-color: #e06e6e !important;
  line-height: 20px !important;
  border-radius: 20px !important;
  padding: 4px 8px 4px 12px !important;

  &:hover {
    background-color: #c25b5b !important;
  }
}