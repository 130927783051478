.text-h1 {
    font-size: 40px !important;
    font-weight: bold !important;
}

.text-p {
    color: rgb(80, 80, 80);
    font-weight: bold !important;
}

.text-button {
    font-size: 25px !important;
    padding: 5px 20px !important;
}