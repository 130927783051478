.text-h1 {
    text-align: center;
    font-size: 40px !important;
    font-weight: 700 !important;
    color: rgb(97, 97, 97);
}

.cancel-button {
    max-height: 45px !important;
    margin-right: 10px !important;
    color: rgb(255, 255, 255) !important;
    background-color: #929292 !important;
    border-radius: 10px !important;
}

.save-button {
    max-height: 45px !important;
    color: rgb(255, 255, 255) !important;
    background-color: #4775d8 !important;
    border-radius: 10px !important;
}

.add-button {
    max-height: 45px !important;
    margin-top: 20px !important;
    color: rgb(36, 114, 59) !important;
    background-color: #69c571 !important;
    border-radius: 10px !important;
}

.edit-button {
    max-height: 45px !important;
    padding: 5px !important;
    margin-right: 10px !important;
    color: #ffffff !important;
    background-color: #3d7bd6 !important;
}

.print-button {
    max-height: 45px !important;
    padding: 5px !important;
    margin-right: 10px !important;
    color: #ffffff !important;
    background-color: #178d3e !important;
}

.delete-button {
    max-height: 45px !important;
    padding: 5px !important;
    color: #ffffff !important;
    background-color: #d63d3d !important;
}

.no-data {
    text-align: center !important;
    font-size: 15px !important;
    font-weight: bold !important;
    color: rgb(138, 138, 138) !important;
}