.main-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.head-box {
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f9fc;
    border-bottom: 1px solid #E5E5E5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1;
}

.head-title {
    font-size: 15px !important;
    font-weight: bold !important;
    color: #000;
    padding: 0 16px;
}

.orders-box {
    min-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 15px 0 !important;
}

.orders-grid {
    padding: 10px 0;
}

.orders-info {
    font-size: 14px !important;
    padding: 0 16px;
}

.order-link {
    color: #000 !important;
    &:hover {
        color: #2b62d8 !important;
    }
}