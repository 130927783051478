.dataCard-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 1px -1px #e8e8e8,
              0px 1px 1px 0px #e8e8e8,
              0px 1px 3px 0px #e8e8e8 !important;
}

.chartCard-title-box {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dialogTitle {
  font-weight: bold;
  text-align: center;
  padding: 16px 0;
}

.dialogContent {
  padding: 20px;
}

.addingTargetBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 10px;
}

.dialogActions {
  display: flex;
  justify-content: center !important;
  padding: 10px 20px;
}

.custom-button {
  border-radius: 5px;
  font-size: 12px;
  text-transform: none;
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
  border: none;
  outline: none;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
}

.cancel-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.03);
}

.save-button {
  background-color: #1976d2;
  color: #fff;
  border: none;
}

.achievement-container {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.progress-wrapper {
  position: relative;
  flex: 1;
  margin-right: 10px;
}

.progress-bar {
  width: 100%;
  height: 8px;
  border-radius: 4px;
}

.progress-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
}

.achievement-percent {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
