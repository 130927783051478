.box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 36px 0px 36px !important;
}

.chip {
    line-height: 20px;
    font-weight: bold;
    padding: 10px !important;
    background-color: #eaf0ff !important;
    border: 1px solid #e0e0e0 !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.reset-button {
    font-weight: bold !important;
    color: #fff !important;
    background-color: #8d8d8d !important;
    border-radius: 15px 15px 0 0 !important;
    border: 1px solid #666666 !important;
    padding: 3px 20px !important;
}

.status-text {
    font-size: 14px !important;
    font-weight: bold !important;
    background-color: #fff;
    border-radius: 5px !important;
    padding: 3px 10px !important;
}

.save-button {
    font-weight: bold !important;
    color: #fff !important;
    background-color: #28a745 !important;
    border-radius: 15px 15px 0 0 !important;
    border: 1px solid #218838 !important;
    padding: 3px 20px !important;
}