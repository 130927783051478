.no-data {
    text-align: center !important;
    font-size: 15px !important;
    font-weight: bold !important;
    color: rgb(138, 138, 138) !important;
}

.edit-button {
    color: #ffffff !important;
    min-width: 24px !important;
    padding: 5px !important;
    margin-right: 5px !important;
    background-color: #3d7bd6 !important;
}

.delete-button {
    color: #ffffff !important;
    min-width: 24px !important;
    padding: 5px !important;
    background-color: #e06e6e !important;
}

.groups-area {
    background-color: burlywood !important;
    border-radius: 5px !important;
    padding: 1px 2.5px !important;
    margin: 0 1.5px !important;
    display: inline-block !important;
}

.main-box {
    margin: 0;
}
  
.card {
    border-radius: 15px !important;
    border-top: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
}
  
.cardcontent {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    overflow: hidden;
    position: relative;
}
  
.view-cardcontent-title-box {
    border-bottom: 1px solid #E5E5E5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
  
.title-label {
    font-size: 15px !important;
    font-weight: bold !important;
    padding: 10px 20px;
}
  
.employee-avatar {
    border: 1px solid #a0a0a0;
    margin: 10px 0 10px 20px;
}
  
.content-label {
    font-size: 15px !important;
    margin-left: 20px !important;
}
  
.view-icon {
    margin-left: 20px !important;
    &:hover {
      color: #007bff;
    }
}
  
.view-cardcontent-content-box {
    min-height: 200px;
    height: calc(100vh - 398px);
    max-height: calc(100vh - 398px);
    overflow-y: auto;
}
  
.view-cardcontent-action-box {
    margin-top: 3px;
}
  
.add-button {
    font-weight: bold !important;
    color: #fff !important;
    background-color: #007bff !important;
    border-radius: 15px 15px 0 0 !important;
    border: 1px solid #0056b3 !important;
    padding: 3px 20px !important;
}
  
.adding-card {
    border-radius: 15px !important;
    border: 1px solid #E5E5E5;
}
  
.adding-cardcontent-title-box {
    border-bottom: 1px solid #E5E5E5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
  
.adding-cardcontent-content-box {
    min-height: 200px;
    height: calc(100vh - 408px);
    max-height: calc(100vh - 408px);
    padding-top: 10px;
    overflow-y: auto;
}
  
.upload-icon {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    color: #fff;
    background-color: #a0a0a0;
    padding: 26px;
    margin: 10px 0;
    opacity: 0;
    position: absolute;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
}
  
.adding-cardcontent-action-box {
    margin-top: 3px;
}
  
.cancel-button {
    font-weight: bold !important;
    color: #fff !important;
    background-color: #dc3545 !important;
    border-radius: 15px 15px 0 0 !important;
    border: 1px solid #9e202d !important;
    padding: 3px 20px !important;
}
  
.submit-button {
    font-weight: bold !important;
    color: #fff !important;
    background-color: #69c571 !important;
    border-radius: 15px 15px 0 0 !important;
    border: 1px solid #3a9241 !important;
    padding: 3px 20px !important;
}

.editing-cardcontent-title-box {
    border-bottom: 1px solid #E5E5E5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.editing-cardcontent-content-box {
    min-height: 200px;
    height: calc(100vh - 408px);
    max-height: calc(100vh - 408px);
    padding-top: 10px;
    overflow-y: auto;
}

.editing-cardcontent-action-box {
    margin-top: 3px;
}

.delete-dialog-content {
    width: 500px;
    height: 200px;
    padding: 20px;
}

.delete-dialog-title {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #f48424ff !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-dialog-subtitle {
    font-size: 14px !important;
    color: #555555 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.delete-cancel-button {
    font-weight: bold !important;
    color: #fff !important;
    background-color: #9b9b9b !important;
    border-radius: 5px !important;
    padding: 3px 10px 3px 9px !important;
}

.delete-confirm-button {
    font-weight: bold !important;
    color: #fff !important;
    background-color: #bb2835 !important;
    border-radius: 5px !important;
    padding: 3px 6px 3px 10px !important;
}

.snackbar {
    transform: translateY(100%);
    opacity: 0;
    animation: slideInUp 1.5s forwards, fadeOut 1.5s forwards 10s;
}

@keyframes slideInUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-100%); /* 讓元素滑出並消失 */
    }
}