.tips-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 60px;
    border: 1px solid #e8e8e8;
    padding: 15px;
    margin-top: 25px;
    box-shadow: 0px 2px 1px -1px #e8e8e8,0px 1px 1px 0px #e8e8e8,0px 1px 3px 0px #e8e8e8;
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    animation: slideInRight 0.5s ease-in-out forwards;
}

@keyframes slideInRight {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.page-title {
    color: #4f4d4d;
    font-size: 28px !important;
    font-weight: bold !important;
    background-color: #FFFFFF;
    position: absolute;
    transform: translate(-50%, -50%);
    top: -7px;
    left: 50%;
}

.tips-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.tips-cancelled {
    color: #702d2c !important;
    font-size: 13px !important;
    font-weight: bold;
    line-height: 20px;
    background-color: #e38785 !important;
    border: 1px solid #c04542 !important;
}

.tips-unpaid {
    color: #4f4d4d !important;
    font-size: 13px !important;
    font-weight: bold;
    line-height: 20px;
    background-color: #b8b5b5 !important;
    border: 1px solid #837f7f !important;
}

.tips-deposit-paid {
    color: #305123 !important;
    font-size: 13px !important;
    font-weight: bold;
    line-height: 20px;
    background-color: #94cf73 !important;
    border: 1px solid #5fa63c !important;
}

.tips-balance-paid {
    color: #703a1e !important;
    font-size: 13px !important;
    font-weight: bold;
    line-height: 20px;
    background-color: #e8ab65 !important;
    border: 1px solid #d16e25 !important;
}

.tips-full-paid {
    color: #243484 !important;
    font-size: 13px !important;
    font-weight: bold;
    line-height: 20px;
    background-color: #5e91f0 !important;
    border: 1px solid #3156df !important;
}

.tips-reception {
    color: #065534 !important;
    font-size: 13px !important;
    font-weight: bold;
    line-height: 20px;
    background-color: #a6e9c5 !important;
    border: 1px solid #00a86b !important;
}

.tips-charter {
    color: #6a1b9a !important;
    font-size: 13px !important;
    font-weight: bold;
    line-height: 20px;
    background-color: #d1c4e9 !important;
    border: 1px solid #9c27b0 !important;
}

.tips-date-range {
    color: #00579c !important;
    font-size: 13px !important;
    font-weight: bold;
    line-height: 20px;
    background-color: #e3f2fd !important;
    border: 1px solid #2196f3 !important;
}

.tips-filter {
    color: #616161 !important;
    font-size: 13px !important;
    font-weight: bold;
    line-height: 20px;
    background-color: #fefaf1 !important;
}

.choose-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5px;
    transform: translateY(-100%);
    z-index: -1;
    visibility: hidden;
    animation: hangDown 0.3s ease-in-out forwards 0.5s;
}

@keyframes hangDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
        visibility: visible;
    }
}

.choose-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tips-text {
    color: #d32f2f !important;
    font-size: 14px !important;
    font-weight: bold;
    line-height: 20px;
}

.hang-design {
    width: 45px;
    height: 8px;
    border-left: 2px solid #989898;
    border-right: 2px solid #989898;
}

.function-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    gap: 10px;
    padding: 0 15px;
}

.functions{
    flex-grow: 1;
}

.table-box {
    padding-bottom: 10px;
    margin: 0;
}

.table-card {
    border-radius: 15px !important;
    border-top: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
}
  
.table-cardcontent {
    width: 100%;
    height: calc(100vh - 64px - 40px - 48px - 111px - 70.5px - 45px - 43.5px);
    max-height: calc(100vh - 64px - 40px - 48px - 111px - 70.5px - 45px - 43.5px);
    min-height: 300px;
    padding: 0 !important;
    overflow: auto;
    position: relative;
}

.cardcontent-title-box {
    min-width: 100%;
    width: fit-content;
    display: flex;
    background-color: #FFFFFF;
    border-bottom: 1px solid #E5E5E5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 2;
}

.title-label {
    font-size: 15px !important;
    font-weight: bold !important;
    flex-grow: 1;
    padding: 10px 16px;
    white-space: nowrap;
}

.cardcontent-content-box {
    min-width: 100%;
    width: fit-content;
    min-height: 288px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 15px 0 !important;
}

.content-row {
    min-width: 100%;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: thin solid #e0e0e0;
    animation: slideInUp 0.8s ease-in-out forwards;
    position: sticky;
    top: 43.5px;
    z-index: 1;
    &:hover {
        background-color: #ebf1fd;

        .content-info {
            background-color: #ebf1fd;
        }
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.content-info {
    flex-grow: 1;
    font-size: 14px !important;
    padding: 0 16px;
}

.link-text {
    color: #000000 !important;
    &:hover {
        color: #3d7bd6 !important;
    }
}

.status-cancelled {
    color: #702d2c;
    background-color: #e38785;
    display: inline;
    border-radius: 15px;
    outline: 1px solid #c04542;
    padding: 5px 10px;
}

.status-unpaid {
    color: #4f4d4d;
    background-color: #b8b5b5;
    display: inline;
    border-radius: 15px;
    outline: 1px solid #837f7f;
    padding: 5px 10px;
}

.status-deposit-paid {
    color: #305123;
    background-color: #94cf73;
    display: inline;
    border-radius: 15px;
    outline: 1px solid #5fa63c;
    padding: 5px 10px;
}

.status-balance-paid {
    color: #703a1e;
    background-color: #e8ab65;
    display: inline;
    border-radius: 15px;
    outline: 1px solid #d16e25;
    padding: 5px 10px;
}

.status-full-paid {
    color: #243484;
    background-color: #5e91f0;
    display: inline;
    border-radius: 15px;
    outline: 1px solid #3156df;
    padding: 5px 10px;
}

.collapse-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e8e8e8;
}

.collapse-title-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: sticky;
    left: 0;
}

.collapse-title-label {
    font-size: 16px !important;
    font-weight: bold !important;
    padding: 0 16px;
    white-space: nowrap;
    overflow: hidden;
}

.collapse-content-box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: sticky;
    left: 0;
}

.collapse-content-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.collapse-textfield {
    flex-grow: 1;
}

.no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 288px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #9E9E9E;
    animation: slideInDown 0.8s ease-in-out forwards;
}

@keyframes slideInDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}