/* .text-h1 {
  text-align: center;
  font-size: 40px !important;
  font-weight: 700 !important;
  color: rgb(97, 97, 97);
}

.tab {
  font-weight: bold !important;
  border-radius: 20px 20px 0 0 !important;
  transition: background-color 0.5s cubic-bezier(.4,0,.2,1), color 0.5s cubic-bezier(.4,0,.2,1), font-size 0.5s, box-shadow 0.5s !important;
  transition-delay: 0.2s !important;
}

.active-tab {
  border-radius: 20px 20px 0 0 !important;
  background-color: rgb(172, 172, 201) !important;
  color: white !important;
  animation: fadeIn 0.5s !important;
}

.localgrouppaymentdetails-title {
  text-align: center !important;
  font-size: 25px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.localgrouppaymentdetails-h1 {
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.localgrouppaymentdetails-label {
  text-align: center !important;
  font-size: 15px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.localgrouppaymentdetails-field {
  text-align: center !important;
  font-size: 14px !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.localgrouppaymentdetails-footer {
  color: rgb(196, 63, 63) !important;
  text-align: center !important;
  font-size: 18px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.costcalculation-title {
  text-align: center !important;
  font-size: 25px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.costcalculation-h1 {
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.costcalculation-label {
  text-align: center !important;
  font-size: 15px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.costcalculation-field {
  text-align: center !important;
  font-size: 14px !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.costcalculation-footer {
  color: rgb(196, 63, 63) !important;
  text-align: center !important;
  font-size: 18px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  min-width: fit-content !important;
}

.tab {
  font-weight: bold !important;
  border-radius: 20px 20px 0 0 !important;
  transition: background-color 0.5s cubic-bezier(.4,0,.2,1), color 0.5s cubic-bezier(.4,0,.2,1), font-size 0.5s, box-shadow 0.5s !important;
  transition-delay: 0.2s !important;
}

.active-tab {
  border-radius: 20px 20px 0 0 !important;
  background-color: rgb(172, 172, 201) !important;
  color: white !important;
  animation: fadeIn 0.5s !important;
}

.no-data {
  text-align: center !important;
  font-size: 15px !important;
  font-weight: bold !important;
  color: rgb(138, 138, 138) !important;
}

.edit-button {
  color: #ffffff !important;
  min-width: 24px !important;
  padding: 5px !important;
  margin-right: 5px !important;
  background-color: #3d7bd6 !important;
}

.delete-button {
    color: #ffffff !important;
    background-color: #e05858 !important;
}

.delete-button-1 {
  color: #ffffff !important;
  background-color: #e05858 !important;
  margin-left: 10px !important;
  margin-top: 4px !important;
}

.save-button {
  color: #ffffff !important;
  min-width: 24px !important;
  padding: 5px !important;
  margin-right: 5px !important;
  background-color: #3fb379 !important;
}

.cancel-button {
  color: rgb(255, 255, 255) !important;
  background-color: #acacac !important;
  min-width: 24px !important;
  padding: 5px !important;
}

.add-button {
    color: rgb(36, 114, 59) !important;
    margin-top: 10px !important;
    background-color: #69c571 !important;
    border-radius: 15px !important;
}

.loaderror-text-h1 {
  font-size: 40px !important;
  font-weight: bold !important;
}

.loaderror-text-p {
  font-weight: bold !important;
}

.nochange-text {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #2e85e7;
}

.success-text {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #28a533;
}

.error-text {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
} */

/* 重構 */
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-title {
  text-align: center;
  font-size: 25px !important;
  font-weight: bold !important;
  color: #323b76;
}

.tab-box {
  width: fit-content;
  display: flex;
  justify-content: center;
  gap: 5px;
  border: 1px solid #d4d4d4;
  border-radius: 15px;
  padding: 4px;
}

.tabs-button {
  color: #525252 !important;
  border-radius: 10px !important;
  padding: 4px !important;

  &:hover {
    background-color: #7c9dde !important;
    color: #fff !important;
    font-weight: bold !important;
  }
}

.tabs-button-selected {
  background-color: #384495 !important;
  color: #fff !important;
  font-weight: bold !important;
  padding: 4px !important;
  border-radius: 10px !important;
}

.tabs-button-icon {
  font-size: 20px !important;
  margin-right: 3px !important;
}