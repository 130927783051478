.dataCard-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e8e8e8;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 1px -1px #e8e8e8,0px 1px 1px 0px #e8e8e8,0px 1px 3px 0px #e8e8e8 !important;
}

.dataCard-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.list-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.list-control-item {
    background-color: #a9c2ed;
    border-radius: 15px;
    padding: 5px;

    &:hover {
        background-color: #4b6ab9;
        cursor: pointer;
    }
}

.dataCard-details-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 5px 10px 10px 10px;
    overflow: auto;
}

.details-card {
    min-width: 186.56px;
    max-width: 186.56px;
    min-height: 181.5px;
    max-height: 181.5px;
    border-radius: 15px !important;
    transform: translateY(100%);
    opacity: 0;
    animation: slideInDown 1s ease-in-out forwards;
    flex-shrink: 0;
}

@keyframes slideInDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.details-card:hover .details-cardcontent {
    transform: translateY(-25%);
    transition: transform 1s;
}

.details-cardcontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 16px !important;
}

.details-icon {
    color: #fff;
    border-radius: 50%;
    padding: 8px;
}

.details-number {
    font-size: 24px !important;
    font-weight: 900 !important;
    color: #1a224c !important;
}

.details-description {
    font-size: 12px !important;
    color: #1a224c !important;
    margin-top: 10px !important;
}

.dataTable-header-box {
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.info-icon {
    color: #4b6ab9;
    animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.export-button {
    line-height: normal !important;
    padding: 0px 10px !important;
    border-radius: 15px !important;
    color: #fff !important;
    font-size: 13px !important;
    transition: all .3s !important;
    position: relative !important;
    overflow: hidden;
    z-index: 1;
    animation: slideInRight 1s ease-in-out forwards;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #4b6ab9;
        border-radius: 15px !important;
        z-index: -2;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #1a224c;
        transition: all .3s;
        border-radius: 15px !important;
        z-index: -1;
    }
    &:hover {
        color: #fff !important;
        &:before {
            width: 100%;
        }
    }
}

.filter-box {
    display: flex;
    gap: 10px;
    background-color: #4b6ab9;
    border-radius: 15px 15px 0 0;
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    padding: 3px 10px;
    animation: slideInRight 1s ease-in-out forwards;
}

@keyframes slideInRight {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.options-box {
    display: flex;
    position: absolute;
    right: 0;
    animation: slideInUp 1s ease-in-out forwards;
}

@keyframes slideInUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.options-button-1 {
    font-weight: bold !important;
    color: #1a224c !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-top: 1px solid #e8e8e8 !important;
    border-right: 1px solid #e8e8e8 !important;
    border-left: 1px solid #e8e8e8 !important;
    border-radius: 15px 0 0 0 !important;
    box-shadow: 0px 2px 1px -1px #e8e8e8,0px 1px 1px 0px #e8e8e8,0px 1px 3px 0px #e8e8e8 !important;
    padding: 6px 25px !important;
    &:hover {
        background-color: #a9c2ed !important;
    }
}

.options-button-2 {
    font-weight: bold !important;
    color: #1a224c !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-top: 1px solid #e8e8e8 !important;
    border-right: 1px solid #e8e8e8 !important;
    border-left: 1px solid #e8e8e8 !important;
    border-radius: 0 15px 0 0 !important;
    box-shadow: 0px 2px 1px -1px #e8e8e8,0px 1px 1px 0px #e8e8e8,0px 1px 3px 0px #e8e8e8 !important;
    padding: 6px 25px !important;
    &:hover {
        background-color: #a9c2ed !important;
    }
}