a {
    color: inherit; /* 文字顏色繼承父元素 */
    text-decoration: none; /* 移除下劃線 */
    background-color: transparent; /* 背景色透明 */
}

.active {
    color: #000;
    background-color: #e0e0e0 !important;
    font-weight: bold;
    text-align: center !important;
    border-radius: 12px !important;
}

.active-closed {
    color: #000;
    background-color: #e0e0e0 !important;
    font-weight: bold;
    text-align: center !important;
}