.main-box {
  margin: 0;
}

.card {
  border-radius: 15px !important;
  border-top: 1px solid #E5E5E5;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
}

.cardcontent {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  overflow: hidden;
}

.view-cardcontent-title-box {
  border-bottom: 1px solid #E5E5E5;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.title-label {
  font-size: 15px !important;
  font-weight: bold !important;
  padding: 10px 20px;
}

.employee-avatar {
  border: 1px solid #a0a0a0;
  margin: 10px 0 10px 20px;
}

.content-label {
  font-size: 15px !important;
  margin-left: 20px !important;
}

.view-icon {
  margin-left: 20px !important;
  &:hover {
    color: #007bff;
  }
}

.view-cardcontent-content-box {
  min-height: 200px;
  height: calc(100vh - 398px);
  max-height: calc(100vh - 398px);
  overflow-y: auto;
}

.view-cardcontent-action-box {
  margin-top: 3px;
}

.add-button {
  font-weight: bold !important;
  color: #fff !important;
  background-color: #007bff !important;
  border-radius: 15px 15px 0 0 !important;
  border: 1px solid #0056b3 !important;
  padding: 3px 20px !important;
}

.adding-card {
  border-radius: 15px !important;
  border: 1px solid #E5E5E5;
}

.adding-cardcontent-title-box {
  border-bottom: 1px solid #E5E5E5;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.adding-cardcontent-content-box {
  min-height: 200px;
  height: calc(100vh - 398px);
  max-height: calc(100vh - 398px);
  overflow-y: auto;
}

.adding-employee-avatar {
  width: 100px !important;
  height: 100px !important;
  border: 1px solid #a0a0a0;
  margin: 10px 0;
}

.upload-icon {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  color: #fff;
  background-color: #a0a0a0;
  padding: 26px;
  margin: 10px 0;
  opacity: 0;
  position: absolute;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.adding-cardcontent-action-box {
  margin-top: 3px;
}

.cancel-button {
  font-weight: bold !important;
  color: #fff !important;
  background-color: #dc3545 !important;
  border-radius: 15px 15px 0 0 !important;
  border: 1px solid #9e202d !important;
  padding: 3px 20px !important;
}

.submit-button {
  font-weight: bold !important;
  color: #fff !important;
  background-color: #69c571 !important;
  border-radius: 15px 15px 0 0 !important;
  border: 1px solid #3a9241 !important;
  padding: 3px 20px !important;
}