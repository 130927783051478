.main-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.print-box {
  padding: 5px;
  position: absolute;
  left: 0;
}

.print-button {
  height: 25px;
  color: #1e3a8a !important; /* 深藍色 */
  border-radius: 10px !important;
  padding: 2px 6px !important;
  background-color: #e0e7ff !important; /* 淡藍色背景 */

  &:hover {
    background-color: #1e40af !important; /* 更深的藍色 */
    color: #ffffff !important; /* 白色字體 */
  }
}

.category-box {
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 4px;
  margin-top: 5px;
}

.category-button {
  height: 25px;
  color: #525252 !important;
  border-radius: 10px !important;
  padding: 2px 4px !important;
  transition: transform 0.3s !important;

  &:hover {
    background-color: #a1a1aa !important;
    color: #fff !important;
    transform: scale(1.1);
  }
}

.category-button-selected {
  height: 25px;
  background-color: #3f3f46 !important;
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  padding: 2px 4px !important;
  transform: scale(1.1);
}

.content-box {
  height: calc(100vh - 104px - 48px - 37.5px - 47.5px - 38px - 20px - 2px - 16px);
  min-height: 300px;
  border: 1px solid #d4d4d4;
  border-radius: 10px 10px 0 0;
  overflow-y: hidden;
}

.tab-main-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.chip-text {
  height: 25px !important;
  font-weight: bold;
  line-height: 15px;
  color: #323b76 !important;
  background-color: #a7bee9 !important;
}

.itineraries-chip-text {
    height: 25px !important;
    font-weight: bold;
    line-height: 15px;
}

.snackbar {
  transform: translateY(100%);
  opacity: 0;
  animation: AlertslideInUp 1.5s forwards, AlertfadeOut 1.5s forwards 5s;
}

@keyframes AlertslideInUp {
  from {
      transform: translateY(100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes AlertfadeOut {
  from {
      opacity: 1;
      transform: translateY(0);
  }
  to {
      opacity: 0;
      transform: translateY(-100%); /* 讓元素滑出並消失 */
  }
}

.action-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border: 1px solid #d4d4d4;
  border-top: 0;
  border-radius: 0 0 10px 10px;
}

.edit-button {
  height: 25px;
  color: #525252 !important;
  border-radius: 10px !important;
  padding: 8px 6px !important;

  &:hover {
    background-color: #d1d1d1 !important;
    font-weight: bold !important;
  }
}

.cancel-button {
  height: 25px;
  color: #878ca7 !important;
  border-radius: 10px !important;
  padding: 8px 6px !important;

  &:hover {
    background-color: #dde1e8 !important;
    font-weight: bold !important;
  }
}

.save-button {
  height: 25px;
  color: #4476ff !important;
  border-radius: 10px !important;
  padding: 8px 6px !important;

  &:hover {
    background-color: #bcd3ff !important;
    font-weight: bold !important;
  }
}