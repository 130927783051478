.main-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.page-title {
    font-size: 30px !important;
    font-weight: bold !important;
    color: #3f51b5;
    cursor: default;
}

.function-box {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filter-button {
    color: #ffffff !important;
    font-size: 15px !important;
    background-color: #3f51b5 !important;
    border-radius: 20px !important;
    padding: 4px 8px 4px 12px !important;

    &:hover {
        background-color: #394494 !important;
    }
}

.create-button {
    color: #ffffff !important;
    font-size: 15px !important;
    background-color: #3f51b5 !important;
    border-radius: 20px !important;
    padding: 4px 12px 4px 8px !important;

    &:hover {
        background-color: #394494 !important;
    }
}

.announcement-box {
    width: 100%;
    max-height: calc(100vh - 64px - 40px - 48px - 45px - 54.25px - 60px - 10px);
    min-height: 250px;
    overflow: auto;
}

.announcement-list {
    width: 97%;
    display: flex !important;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    margin-bottom: 10px;
}

.pinned-icon {
    color: #f87171 !important;
    position: absolute;
    top: -10px;
    left: -9px;
    transform: rotate(-40deg);
    transition: color 0.5s, font-size 0.5s, top 0.5s, left 0.5s !important;
}

.timer-icon {
    color: #f59e0b !important;
    position: absolute;
    top: -10px;
    right: -9px;
    transform: rotate(40deg);
    transition: font-size 0.5s !important;
}

.announcement-card {
    border-radius: 15px !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
    transition: box-shadow 0.5s, height 0.5s !important;
    padding: 25px 25px 0 25px;
    position: relative;
    opacity: 0;
    transform: translateX(-100%);
    animation: fadeInFromLeft 1s ease-out forwards; 

    &:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3) !important;

        .pinned-icon {
            color: #dc2626 !important;
            font-size: 28px;
            top: -5px;
            left: -4px;
        }

        .timer-icon {
            font-size: 30px;
            animation: ring 1s infinite ease-in-out;
        }

        .announcement-title {
            color: #3f51b5 !important;
            transform: translateX(15px);
        }

        .announcement-type {
            transform: translateX(-15px);
        }

        .expand-icon {
            color: #3f51b5 !important;
        }
    }
}

@keyframes fadeInFromLeft {
    from {
        opacity: 0; /* 初始為完全透明 */
        transform: translateX(-100%); /* 從左側外部開始 */
    }
    to {
        opacity: 1; /* 最終為完全可見 */
        transform: translateX(0); /* 滑入到初始位置 */
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%); /* 元素從視窗左側外開始 */
    }
    to {
        transform: translateX(0); /* 元素滑入到視窗內 */
    }
}

@keyframes ring {
    0% { transform: rotate(40); }
    1% { transform: rotate(70deg); }
    3% { transform: rotate(12deg); }
    5% { transform: rotate(74deg); }
    7% { transform: rotate(8deg); }
    9% { transform: rotate(70deg); }
    11% { transform: rotate(12deg); }
    13% { transform: rotate(66deg); }
    15% { transform: rotate(16deg); }
    17% { transform: rotate(62deg); }
    19% { transform: rotate(20deg); }
    21% { transform: rotate(58deg); }
    23% { transform: rotate(24deg); }
    25% { transform: rotate(54deg); }
    27% { transform: rotate(28deg); }
    29% { transform: rotate(50deg); }
    31% { transform: rotate(32deg); }
    33% { transform: rotate(46deg); }
    35% { transform: rotate(36deg); }
    37% { transform: rotate(42deg); }
    39% { transform: rotate(39deg); }
    41% { transform: rotate(41deg); }
    43% { transform: rotate(40); }
    100% { transform: rotate(40); }
}

.title-box {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.announcement-title {
    font-size: 18px !important;
    font-weight: bold !important;
    transition: color 0.3s, transform 0.7s !important;
}

.announcement-type {
    font-size: 13px !important;
    font-weight: bold !important;
    color: #FFF;
    border-radius: 15px;
    padding: 1px 6px;
    transition: transform 0.7s !important;
}

.announcement-content {
    animation: slideInFromLeft 0.5s ease-out forwards;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.announcement-info {
    display: flex !important;
    justify-content: end;
}

.expand-icon {
    transition: color 0.3s, transform 0.3s !important;
}

.no-data {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #9E9E9E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: slideInFromLeft 0.8s ease-in-out forwards;
}

.action-box {
    display: flex !important;
    justify-content: center;
    margin: 0 0 10px 0;
}

.action-buttons {
    display: flex !important;
    gap: 25px;
}

.edit-button {
    min-width: 0 !important;
    color: #4a5989 !important;
    background-color: #f8f8f8 !important;
    border-radius: 50% !important;
    padding: 6px !important;

    &:hover {
        background-color: #dcdcdc !important;
    }
}

.delete-button {
    min-width: 0 !important;
    color: #df5454 !important;
    background-color: #f8f8f8 !important;
    border-radius: 50% !important;
    padding: 6px !important;

    &:hover {
        background-color: #dcdcdc !important;
    }
}

.create-content-box {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
}

.create-content-row {
    display: flex !important;
    flex-direction: row;
    gap: 10px;
}

.cancel-button {
    color: #ffffff !important;
    background-color: #acacac !important;
    line-height: 20px !important;   
    border-radius: 20px !important;
    padding: 4px 12px 4px 8px !important;

    &:hover {
        background-color: #8c8c8c !important;
    }
}

.submit-button {
    color: #ffffff !important;
    background-color: #3f51b5 !important;
    line-height: 20px !important;
    border-radius: 20px !important;
    padding: 4px 8px 4px 12px !important;

    &:hover {
        background-color: #394494 !important;
    }
}

.delete-confirm-button {
    color: #ffffff !important;
    background-color: #e06e6e !important;
    line-height: 20px !important;
    border-radius: 20px !important;
    padding: 4px 8px 4px 12px !important;

    &:hover {
        background-color: #c25b5b !important;
    }
}